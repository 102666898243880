<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">实验终点</h3>
    <div class="detail">
      <el-form ref="addForm" size="small" :model="drugForm" class="addForm" label-suffix=":"
               label-width="90px">
        <div class="tit flex">
          <h3>大体解剖观察记录</h3>
          <p class="remarkTit">备注：①急性失血，②空气栓塞，③脊椎脱臼，④其他</p>
        </div>
        <el-tabs v-model="tabsValue" class="tabsCage">
          <el-tab-pane
              :key="index"
              v-for="(item, index) in tabs"
              :label="item.tit"
              :name="item.tit"
          >
            <el-table
                :data="item.tableDate" border
                class="tableMember"
                :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
                style="width: calc(100% - 12px);font-size:14px;color:#333">
              <el-table-column label="动物编号" prop="animalNum" show-overflow-tooltip></el-table-column>
              <el-table-column label="耳号" prop="earNum" show-overflow-tooltip></el-table-column>
              <el-table-column label="性别">
                <template slot-scope="scope">{{ scope.row.sex == 1 ? '♂' : '♀' }}</template>
              </el-table-column>
              <el-table-column label="死亡类型" class-name="addClass">
                <template slot-scope="scope">
                  <span v-if="!scope.row.show">{{ scope.row.type }}</span>
                  <template v-if="scope.row.show">
                    <el-input size="small" placeholder="请输入死亡类型" v-model="scope.row.type"></el-input>
                  </template>
                </template>
              </el-table-column>
              <el-table-column label="大体解剖观察" class-name="addClass" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="!scope.row.show">{{ scope.row.result }}</span>
                  <template v-if="scope.row.show">
                    <el-input size="small" placeholder="请输入大体解剖观察" v-model="scope.row.result"></el-input>
                  </template>
                </template>
              </el-table-column>
              <el-table-column label="处理方式" class-name="addClass" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="!scope.row.show">{{ scope.row.way }}</span>
                  <template v-if="scope.row.show">
                    <el-input size="small" placeholder="请输入处理方式" v-model="scope.row.way"></el-input>
                  </template>
                </template>
              </el-table-column>
              <el-table-column label="备注" class-name="addClass" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="!scope.row.show">{{ scope.row.remark }}</span>
                  <template v-if="scope.row.show">
                    <el-input size="small" placeholder="请输入备注" v-model="scope.row.remark"></el-input>
                  </template>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="60">
                <template slot-scope="scope">
                  <div class="editColor">
                    <el-button type="text" @click="edit(item,scope.row,scope.$index)">{{ scope.row.show ? '保存' : "修改" }}
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="btn" style="margin-left: 0;">
              <el-button size="small" type="primary" @click="editAll(item)">批量编辑</el-button>
              <el-button size="small" type="primary" @click="submit(item)">批量保存</el-button>
            </div>
          </el-tab-pane>
        </el-tabs>
        <h4 class="title">操作人信息</h4>
        <div class="desc">
          <span>操作人：{{ drugForm.cellReviewer }}</span>
          <span>操作日期：{{ drugForm.createTime }}</span>
        </div>
        <div class="fromSave">
          <el-button type="primary" size="small">本次记录完成</el-button>
          <el-button type="primary" size="small">记录结束</el-button>
          <el-button type="primary" plain size="small">保存</el-button>
          <el-button type="primary" plain size="small">导出预览</el-button>
        </div>
      </el-form>
    </div>
  </div>

</template>

<script>
export default {
  name: "end",
  data() {
    return {
      drugForm: {
        volume: "",
        symptoms: "",
        cellReviewer: "fff",
        createTime: "2020/12/12"
      },
      tabsValue: '实验组1-低剂量组',
      tabs: [
        {
          tit: "实验组1-低剂量组",
          tableDate: [
            {animalNum: "f", type: "1"},
            {animalNum: "f", type: ""}
          ],
        }, {
          tit: "实验组2-低剂量组",
          tableDate: [
            {animalNum: "rt"}
          ],
        }
      ],
    }
  },
  created() {
    this.tabs.forEach((item)=>{
      item.tableDate.map(i => {
        i.show = false
        return i
      })
    })
   /* this.tabs*/
  },
  mounted() {
  },
  methods: {
    // 编辑
    edit(item, row, index) {
      row.show = !row.show
      this.$set(item.tableDate, index, row)
    },
    // 批量编辑
    editAll(item) {
      item.tableDate.map((i, index) => {
        i.show = true
        this.$set(item.tableDate, index, i)
      })
    },
    // 批量保存
    submit(item) {
      item.tableDate.map((i, index) => {
        i.show = false
        this.$set(item.tableDate, index, i)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";
</style>
